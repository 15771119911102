var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"customer full-child-height\" data-region=\"customer\"></div>\r\n<div class=\"description designer-box rounded\">\r\n    <textarea name=\"description\" id=\"description\" class=\"form-control\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"note_on_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":3,"column":84},"end":{"line":3,"column":122}}}))
    + "\" data-ui=\"description\"></textarea>\r\n</div>\r\n<div class=\"payment-methods full-child-height\" data-region=\"payment-methods\"></div>\r\n<div class=\"product-list designer-box rounded\">\r\n    <div data-region=\"product-list\"></div>\r\n</div>\r\n<div class=\"payment-list\" data-region=\"payment-list\"></div>\r\n<div class=\"payment-total\" data-region=\"payment-totals\"></div>\r\n<div class=\"product-total full-child-height\" data-region=\"product-totals\"></div>\r\n<div class=\"keypad designer-box rounded\" data-region=\"keypad\"></div>\r\n<div class=\"pay full-child-height\">\r\n    <div class=\"payment-btns\">\r\n        <button class=\"btn btn-info btn-block designer-box rounded\" data-ui=\"delivery\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"partial_delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":87},"end":{"line":15,"column":126}}}))
    + "</button>\r\n        <button disabled class=\"btn btn-success btn-block designer-box rounded\" data-action=\"pay\" data-ui=\"pay\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":112},"end":{"line":16,"column":138}}}))
    + "</button>\r\n    </div>\r\n</div>\r\n\r\n<div data-region=\"popup\" class=\"hidden\"></div>\r\n";
},"useData":true});