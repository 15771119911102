var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"search-container designer-box rounded full-width\">\r\n    <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\r\n        <div class=\"input-group\">\r\n            <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"type_here_to_search_for_a_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":4,"column":65},"end":{"line":4,"column":119}}}))
    + "\" data-action=\"search\" data-ui=\"search\">\r\n            <span class=\"input-group-btn\">\r\n                <button class=\"btn btn-regular btn-primary\" data-action=\"clear\" type=\"button\">\r\n                    "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"search_again",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":7,"column":20},"end":{"line":7,"column":55}}}))
    + "\r\n                </button>\r\n            </span>\r\n        </div>\r\n    </form>\r\n</div>\r\n\r\n<div>\r\n    <div class=\"loader-wrapper\" data-name=\"main\"><div class=\"loader\"></div></div>\r\n    <div class=\"product-container designer-box rounded\">\r\n        <div data-region=\"items\"></div>\r\n\r\n        <div class=\"more full-child-height\">\r\n            <button class=\"btn btn-primary btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":88},"end":{"line":20,"column":120}}}))
    + "</button>\r\n            <div class=\"loader-wrapper\" data-name=\"more\"><div class=\"loader\"></div></div>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});