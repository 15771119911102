var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\r\n    <h4>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":36}}}))
    + "</h4>\r\n    <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"totalCls") || (depth0 != null ? lookupProperty(depth0,"totalCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalCls","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":29}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"total","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":40}}}) : helper)))
    + "</span>\r\n</div>\r\n<div>\r\n    <h4>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"to_pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":6,"column":37}}}))
    + "</h4>\r\n    <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"toPayCls") || (depth0 != null ? lookupProperty(depth0,"toPayCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"toPayCls","hash":{},"data":data,"loc":{"start":{"line":7,"column":17},"end":{"line":7,"column":29}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"toPay") || (depth0 != null ? lookupProperty(depth0,"toPay") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"toPay","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":40}}}) : helper)))
    + "</span>\r\n</div>\r\n<div>\r\n    <h4>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"spare_change",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":10,"column":43}}}))
    + "</h4>\r\n    <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"spareChangeCls") || (depth0 != null ? lookupProperty(depth0,"spareChangeCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"spareChangeCls","hash":{},"data":data,"loc":{"start":{"line":11,"column":17},"end":{"line":11,"column":35}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"spareChange") || (depth0 != null ? lookupProperty(depth0,"spareChange") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"spareChange","hash":{},"data":data,"loc":{"start":{"line":11,"column":37},"end":{"line":11,"column":52}}}) : helper)))
    + "</span>\r\n</div>\r\n";
},"useData":true});