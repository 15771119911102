var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <span class=\"label label-danger\"> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"canceled",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":46},"end":{"line":9,"column":77}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-danger\"> "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"needs_payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":42},"end":{"line":12,"column":78}}}))
    + "</span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"label label-warning\"> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":43},"end":{"line":15,"column":72}}}))
    + "</span><br/>\r\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"customerName") || (depth0 != null ? lookupProperty(depth0,"customerName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"customerName","hash":{},"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":16,"column":24}}}) : helper)))
    + "\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"label label-info\"> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":40},"end":{"line":18,"column":71}}}))
    + "</span><br/>\r\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"addressLine1") || (depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"addressLine1","hash":{},"data":data,"loc":{"start":{"line":19,"column":8},"end":{"line":19,"column":24}}}) : helper)))
    + "<br/>\r\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"addressLine2") || (depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"addressLine2","hash":{},"data":data,"loc":{"start":{"line":20,"column":8},"end":{"line":20,"column":24}}}) : helper)))
    + "\r\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + alias4(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":25,"column":8},"end":{"line":25,"column":22}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":38}}}) : helper)))
    + ")\r\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + alias4(((helper = (helper = lookupProperty(helpers,"distance") || (depth0 != null ? lookupProperty(depth0,"distance") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"distance","hash":{},"data":data,"loc":{"start":{"line":27,"column":8},"end":{"line":27,"column":20}}}) : helper)))
    + "km - "
    + alias4(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":27,"column":25},"end":{"line":27,"column":39}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":27,"column":41},"end":{"line":27,"column":55}}}) : helper)))
    + ")\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\r\n<div class=\"loader-wrapper\" data-name=\"information-item\">\r\n    <div class=\"loader\"></div>\r\n</div>\r\n<div class=\"time\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"timeConfirmed") || (depth0 != null ? lookupProperty(depth0,"timeConfirmed") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timeConfirmed","hash":{},"data":data,"loc":{"start":{"line":5,"column":18},"end":{"line":5,"column":35}}}) : helper)))
    + "</div>\r\n<div class=\"time-requested\"><span class=\"label label-default\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"timeRequested") || (depth0 != null ? lookupProperty(depth0,"timeRequested") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timeRequested","hash":{},"data":data,"loc":{"start":{"line":6,"column":63},"end":{"line":6,"column":80}}}) : helper)))
    + " </span></div>\r\n<div class=\"details\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCanceled") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":10,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":13,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":4},"end":{"line":21,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n<div class=\"summary\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":28,"column":11}}})) != null ? stack1 : "")
    + "</div>\r\n\r\n";
},"useData":true});