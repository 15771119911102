var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"hospitality\">\r\n            <i class=\"fas fa-utensils\"></i>\r\n        </button>\r\n    </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"online-food-order\">\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlineFoodOrderCount") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":19,"column":19}}})) != null ? stack1 : "")
    + "            <i class=\"fas fa-hamburger\"></i>\r\n        </button>\r\n    </div>\r\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"badge badge-danger\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"onlineFoodOrderCount") || (depth0 != null ? lookupProperty(depth0,"onlineFoodOrderCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"onlineFoodOrderCount","hash":{},"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":73}}}) : helper)))
    + "</span>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"calendar\">\r\n            <i class=\"fas fa-calendar\"></i>\r\n        </button>\r\n    </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"repairs\">\r\n            <i class=\"fas fa-wrench\"></i>\r\n        </button>\r\n    </div>\r\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"daily-reports\">\r\n            <i class=\"fas fa-file-check\"></i>\r\n        </button>\r\n    </div>\r\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"sticker-print\">\r\n            <i class=\"fas fa-print\"></i>\r\n        </button>\r\n    </div>\r\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"settings\">\r\n            <i class=\"fas fa-cogs\"></i>\r\n        </button>\r\n    </div>\r\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\r\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"backoffice\">\r\n            <i class=\"fas fa-briefcase\"></i>\r\n        </button>\r\n    </div>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"full-child-height\">\r\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"checkout\">\r\n        <i class=\"fas fa-euro-sign\"></i>\r\n    </button>\r\n</div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showHospitality") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOnlineFood") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":23,"column":7}}})) != null ? stack1 : "")
    + "\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCalendar") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":0},"end":{"line":31,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"full-child-height\">\r\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"return-products\">\r\n        <i class=\"fas fa-exchange-alt\"></i>\r\n    </button>\r\n</div>\r\n<div class=\"full-child-height\">\r\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"customer\">\r\n        <i class=\"fas fa-users\"></i>\r\n    </button>\r\n</div>\r\n<div class=\"full-child-height\">\r\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"catalog\">\r\n        <i class=\"fas fa-th-large\"></i>\r\n    </button>\r\n</div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRepairs") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":47,"column":0},"end":{"line":53,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDailyReports") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":0},"end":{"line":60,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"full-child-height\">\r\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"open-orders\">\r\n        <i class=\"fas fa-browser\"></i>\r\n    </button>\r\n</div>\r\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showStickerPrint") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":0},"end":{"line":72,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSettings") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":0},"end":{"line":79,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBackoffice") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":0},"end":{"line":86,"column":7}}})) != null ? stack1 : "")
    + "\r\n<div class=\"connection-status full-child-height\" data-region=\"connection-status\"></div>\r\n\r\n<div class=\"bottom full-child-height\">\r\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"drawer\">\r\n        <i class=\"fas fa-inbox-out\"></i>\r\n    </button>\r\n</div>\r\n\r\n\r\n<div data-region=\"popup\" class=\"hidden\"></div>\r\n";
},"useData":true});