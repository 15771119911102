var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression;

  return "<div class=\"new-part \">\r\n    <h2 class=\" designer-box rounded\"><i class=\"fal fa-inbox\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"new",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":67},"end":{"line":2,"column":93}}}))
    + "</h2>\r\n    <div data-region=\"list-new\"></div>\r\n</div>\r\n<div class=\"confirmed-part\">\r\n    <h2 class=\" designer-box rounded\"><i class=\"fal fa-calendar-check\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"confirmed",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":76},"end":{"line":6,"column":108}}}))
    + "</h2>\r\n    <div data-region=\"list-confirmed\"></div>\r\n</div>\r\n<div class=\"in-kitchen-part \">\r\n    <h2 class=\" designer-box rounded\"><i class=\"fal fa-hat-chef\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"in_kitchen",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":70},"end":{"line":10,"column":103}}}))
    + "</h2>\r\n    <div data-region=\"list-in-kitchen\"></div>\r\n</div>\r\n<div class=\"complete-part \">\r\n    <h2 class=\" designer-box rounded\"><i class=\"fal fa-sack-dollar\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"complete",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":73},"end":{"line":14,"column":104}}}))
    + "</h2>\r\n    <div data-region=\"list-complete\"></div>\r\n</div>\r\n";
},"useData":true});