var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"text-danger h4 m-0\">"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"total_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":12,"column":49},"end":{"line":12,"column":110}}}))
    + "</span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span>"
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"total_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":14,"column":22},"end":{"line":14,"column":83}}}))
    + "</span>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\r\n    <div class=\"col-xs-4\">\r\n        <button class=\"btn btn-primary w-100 h-100 designer-box rounded\" data-action=\"back\">\r\n            <i class=\"fas fa-arrow-left\"></i>\r\n            "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":39}}}))
    + "\r\n        </button>\r\n    </div>\r\n    <div class=\"col-xs-4\">\r\n        <div class=\"w-100 h-100 designer-box rounded block-column-center themed-background-light\">\r\n            <strong class=\"h4 m-0 mb-2\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"outstanding",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":74}}}))
    + "</strong>\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"total_outstanding_wt") : depth0),">",0,{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":15,"column":31}}})) != null ? stack1 : "")
    + "        </div>\r\n    </div>\r\n    <div class=\"col-xs-4\">\r\n        <div class=\"w-100 h-100 designer-box rounded block-column-center themed-background-light\">\r\n            <strong class=\"h4 m-0 mb-2\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":40},"end":{"line":20,"column":68}}}))
    + "</strong>\r\n            <span class=\"text-success h4 m-0\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"invoice_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":21,"column":46},"end":{"line":21,"column":109}}}))
    + "</span>\r\n        </div>\r\n    </div>\r\n</div>\r\n";
},"useData":true});