var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loader-wrapper\">\r\n    <div class=\"loader\"></div>\r\n</div>\r\n\r\n<form method=\"post\" id=\"login\" class=\"user-pass-form form-horizontal form-bordered form-control-borderless\" onsubmit=\"return false;\" autocomplete=\"off\">\r\n\r\n\r\n    <div class=\"form-group\">\r\n        <div class=\"login-logo\"></div>\r\n    </div>\r\n\r\n    <div class=\"form-group text-input\">\r\n        <div class=\"input-group\">\r\n            <span class=\"input-group-addon\"><i class=\"fal fa-envelope\"></i></span>\r\n            <input type=\"email\" id=\"email\" name=\"email\" class=\"form-control input-lg\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"email",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":15,"column":99},"end":{"line":15,"column":127}}}))
    + "\" value=\"\" autocomplete=\"off\">\r\n        </div>\r\n    </div>\r\n    <div class=\"form-group text-input\">\r\n        <div class=\"input-group\">\r\n            <span class=\"input-group-addon\"><i class=\"fal fa-asterisk\"></i></span>\r\n            <input type=\"password\" id=\"password\" name=\"password\" class=\"form-control input-lg\" placeholder=\""
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"password",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":108},"end":{"line":21,"column":139}}}))
    + "\" value=\"\" autocomplete=\"off\">\r\n        </div>\r\n    </div>\r\n\r\n    <div class=\"form-group form-actions text-right\">\r\n        <button type=\"submit\" class=\"btn btn-sm btn-primary btn-regular\"><i class=\"fa fa-arrow-right\"></i> "
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"login",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":107},"end":{"line":26,"column":135}}}))
    + "</button>\r\n    </div>\r\n</form>\r\n\r\n\r\n<div class=\"app-lock\" data-ui=\"pin\">\r\n    <div class=\"title\">\r\n        <span data-ui=\"title\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":33,"column":30},"end":{"line":33,"column":39}}}) : helper)))
    + "</span>\r\n    </div>\r\n    <div class=\"pin-input\" data-ui=\"input\">\r\n        <i class=\"far fa-circle\" data-index=\"0\"></i>\r\n        <i class=\"far fa-circle\" data-index=\"1\"></i>\r\n        <i class=\"far fa-circle\" data-index=\"2\"></i>\r\n        <i class=\"far fa-circle\" data-index=\"3\"></i>\r\n    </div>\r\n    <div class=\"keypad-input\">\r\n        <div data-action=\"pin\" class=\"pin-keys\">\r\n            <button class=\"btn input-1 designer-box\" data-value=\"1\">1</button>\r\n            <button class=\"btn input-2 designer-box\" data-value=\"2\">2</button>\r\n            <button class=\"btn input-3 designer-box\" data-value=\"3\">3</button>\r\n\r\n            <button class=\"btn input-4 designer-box\" data-value=\"4\">4</button>\r\n            <button class=\"btn input-5 designer-box\" data-value=\"5\">5</button>\r\n            <button class=\"btn input-6 designer-box\" data-value=\"6\">6</button>\r\n\r\n            <button class=\"btn input-7 designer-box\" data-value=\"7\">7</button>\r\n            <button class=\"btn input-8 designer-box\" data-value=\"8\">8</button>\r\n            <button class=\"btn input-9 designer-box\" data-value=\"9\">9</button>\r\n\r\n            <button class=\"btn input-0 designer-box\" data-value=\"0\">0</button>\r\n            <button class=\"btn input-back designer-box\" data-value=\"back\"><i class=\"fas fa-chevron-left\"></i></button>\r\n        </div>\r\n    </div>\r\n\r\n</div>\r\n\r\n<div class=\"device-info\">\r\n    <div class=\"text\">v. "
    + alias2(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":63,"column":25},"end":{"line":63,"column":36}}}) : helper)))
    + ", "
    + alias2(((helper = (helper = lookupProperty(helpers,"deviceUuid") || (depth0 != null ? lookupProperty(depth0,"deviceUuid") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"deviceUuid","hash":{},"data":data,"loc":{"start":{"line":63,"column":38},"end":{"line":63,"column":54}}}) : helper)))
    + " on "
    + alias2(((helper = (helper = lookupProperty(helpers,"account") || (depth0 != null ? lookupProperty(depth0,"account") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"account","hash":{},"data":data,"loc":{"start":{"line":63,"column":58},"end":{"line":63,"column":69}}}) : helper)))
    + "</div>\r\n</div>";
},"useData":true});