var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <div>\r\n                <i class=\"fas fa-ellipsis-h text-info\"></i>\r\n                <span>\r\n                    "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"connecting",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":20},"end":{"line":10,"column":53}}}))
    + "\r\n                </span>\r\n            </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"internet") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":28,"column":19}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "                <div>\r\n                    <i class=\"fas fa-long-arrow-right text-success\"></i>\r\n                    <span>\r\n                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"connected",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":18,"column":56}}}))
    + "\r\n                    </span>\r\n                </div>\r\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                <div>\r\n                    <i class=\"fas fa-long-arrow-right text-danger\"></i>\r\n                    <span>\r\n                        "
    + container.escapeExpression(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"not_connected",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":60}}}))
    + "\r\n                    </span>\r\n                </div>\r\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"backend") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":39,"column":12},"end":{"line":53,"column":19}}})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"no-internet\">\r\n    <span>"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"no_internet_connection",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":10},"end":{"line":2,"column":55}}}))
    + "</span>\r\n\r\n    <div class=\"connection-status\">\r\n        <i class=\"fas fa-desktop-alt text-light\"></i>\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"internet") : depth0),"===",null,{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":29,"column":27}}})) != null ? stack1 : "")
    + "        <i class=\"fas fa-globe text-light\"></i>\r\n"
    + ((stack1 = require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\compare.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"backend") : depth0),"===",null,{"name":"common$compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":54,"column":27}}})) != null ? stack1 : "")
    + "        <i class=\"fas fa-server text-light\"></i>\r\n    </div>\r\n\r\n    <div class=\"settings-container\">\r\n        <button class=\"settings-btn btn btn-light btn-regular designer-box rounded\" data-action=\"retry\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"retry",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":59,"column":104},"end":{"line":59,"column":132}}}))
    + "</button>\r\n        <button class=\"settings-btn btn btn-light btn-regular designer-box rounded\" data-action=\"settings\">"
    + alias2(require("C:\\Users\\Admin\\Coding\\app-cash-register-ui\\app\\scripts\\modules\\common\\templates\\helpers\\translate.js").call(alias1,"click_to_open_network_settings",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":60,"column":107},"end":{"line":60,"column":160}}}))
    + "</button>\r\n    </div>\r\n</div>";
},"useData":true});